import { Card, Typography, Box } from '@material-ui/core';
import { PeopleAltOutlined } from '@material-ui/icons';
import { fNumber } from '../../../utils/formatNumber'; // Ensure this function is correctly imported

export default function AppTotalActiveUsers({ customers }) {
  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: 3,
        height: '150px',
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        border: '1px solid #000',
        backdropFilter: 'blur(8px)',
        WebkitBackdropFilter: 'blur(8px)',
      }}
    >
      <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
        <PeopleAltOutlined style={{ color: '#000' }} />
        <Typography variant="subtitle2">Customers</Typography>
        {/* Display customers count */}
        <Typography color="#000" variant="h3">
          {customers?customers + 1 : 0} {/* Format number if needed */}
        </Typography>
      </Box>
    </Card>
  );
}
