export const GOOGLE_API_KEY = "AIzaSyBe6vrykAsiKjwIHWOUcfH1Li4VT-2LHTg";

// export const REST_API_END_POINT = "http://localhost:5000/webservice/";
// export const REST_API = "http://localhost:5000/";
// export const BASE_URL = "http://localhost:3000/";

// export const REST_API_END_POINT = "http://batman-api.skyniche.website/webservice/";
// export const REST_API = "http://batman-api.skyniche.website/"; 
// export const BASE_URL = "http://batman.skyniche.website/";

export const REST_API_END_POINT = "https://bagempirela.com/backend/webservice/";
export const REST_API = "https://bagempirela.com/backend/";
export const BASE_URL = "https://bagempirela.com/";

export const LBS_CONSTANT= 453.59237;
export const Build = '1.34';
export const Features = [
  { id: 1, label: 'Logo and Name Replace' },
  { id: 2, label: 'Completed Sales Report UI' },
]
export const BugFixes = [
  // { id: 1, label: 'Print label' },
  // { id: 2, label: 'Receive Inventory' },
  // { id: 3, label: 'Bug in tax amount while editing price in cart' },
  // { id: 4, label: 'POS UI design break' },
  // { id: 5, label: 'Displaying wrong amount on product with wholesale tax' },
  // { id: 6, label: 'Accepting negative values while editing price in cart' },
  // { id: 7, label: 'Order refund calculation' },
  // { id: 8, label: 'Room grid view with funtionality' },
  // { id: 9, label: 'Bug while placing order with more than 10 products' },
  { id: 1, label: 'Style Breaks' },
]
export const OUNCE_CONSTANT = 28;
export const FACILITY_ID = 7;
export const INVENTORY_SOURCES = { '0': 'Inventory', '1': 'Grow House', '2': 'Vendor', '3': 'Extractor', '4': 'Rotovap', '5': 'Distillator', '6': 'Kitchen', '7': 'Oven', '8': 'MCP' };


