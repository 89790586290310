import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Card,
  Tooltip
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import { MHidden, MIconButton } from '../../@material-extend';
import AuthLayout from 'src/layouts/AuthLayout';
import { styled } from '@material-ui/styles';
import image from '../../../logo/image.png'

// ----------------------------------------------------------------------
const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

export default function LoginForm() {
  const { login, user } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });
  const Navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        const response = await login(values.email, values.password);

        enqueueSnackbar('Login success', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });

        if(response.user.user_type == 3){
          Navigate('/shop')
        }else{
          Navigate('/dashboard/app')
        }
        
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: ('Invalid email or password') });
        }
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
        <Typography variant='h4' sx={{mt:1,color:{xs:'black',md:'black'}}}>{user?.facility_name || 'Get Go'} Login</Typography>
        <img src={image} height='60' width='60' style={{marginBottom:'20px'}}/>
      </Stack>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            required
            id="outlined"
            label="Username or Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            InputLabelProps={{
              style: { color: 'black' },  // Placeholder color
            }}
            InputProps={{
              style: { color: 'black' },  // Text color
              notchedOutline: { borderColor: 'black' },  // Always visible black border
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'black',  // Default border color
                },
                '&:hover fieldset': {
                  borderColor: 'black',  // Border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'black',  // Border color on focus
                },
              },
            }}
          />
         <TextField
            fullWidth
            id="outlined"
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}  // Toggle between text and password
            label={showPassword ? 'Password' : 'Password      '}
            {...getFieldProps('password')}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            InputLabelProps={{
              style: { color: 'black' },  // Placeholder color
            }}
            InputProps={{
              style: { color: 'black', borderColor: 'black' },  // Text color and border color
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={showPassword ? 'Hide password' : 'Show password'}>
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeOffFill : eyeFill} />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
              sx: {
                WebkitTextSecurity: showPassword ? 'none' : 'disc',  // Conditionally apply mask based on state
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'black',  // Default border color
                },
                '&:hover fieldset': {
                  borderColor: 'black',  // Border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'black',  // Border color on focus
                },
              },
            }}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          /> */}

          <Link component={RouterLink} sx={{color:{xs:'black',md:'black'}}} variant="subtitle2" to={PATH_AUTH.resetPassword}>
            Forgot password?
          </Link>
          <LoadingButton sx={{ background: '#000', borderRadius: '50px' }} size="large" type="submit" variant="contained" loading={isSubmitting}>
            Login
          </LoadingButton>
        </Stack>
      
 {/* <Typography sx={{alignItems:'center',textAlign:'center'}}>
          New to Batman Distribution ? &nbsp;
          <Link underline="none" color={'#000'} variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
            Sign Up Now!
          </Link>
        
     
 </Typography> */}

      </Form>
    </FormikProvider>
  );
}
