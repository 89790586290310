import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Button, AppBar, Toolbar, Container } from '@material-ui/core';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import Logo from '../../components/Logo';
import Label from '../../components/Label';
import { MHidden, MIconButton } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import { ShoppingBag, ShoppingCart } from '@material-ui/icons';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 88;
// const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const navigate=useNavigate()
  const { user,logout } = useAuth(); 
  const { enqueueSnackbar } = useSnackbar();


  const handleLogout = async () => {
   
    try {
      await logout();
      enqueueSnackbar('Logout successful', { variant: 'success' });
      navigate('/');      
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };
  
  const updatedNavConfig = [
    ...navConfig,
    ...(user?.user_type === 3 ? [{
      title: 'My Orders',
      icon: <ShoppingBag />,
      path: '/my-orders'
    }] : []),
  ].map((item) => {
    // Update 'My Account' to 'Logout' if user_type is 3
    if (item.title === 'My Account' && user?.user_type === 3) {
      return {
        ...item,
        title: 'Logout',
        path: '/',
        onClick: handleLogout 
      };
    }
    if (item.title === 'My Account' &&user !==null&&  user?.user_type !== 3) {
      return {
        ...item,
        title: 'Dashboard',        
        path: '/dashboard/app',
      };
    }
    return item;
  });

 

  return (
    <AppBar color={isHome ? 'default' : 'default'} sx={{ boxShadow: 0 }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            // height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={updatedNavConfig} />
          </MHidden>

          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} menuConfig={updatedNavConfig} />
          </MHidden>
          {/* <MIconButton variant="contained" target="_blank">
            <ShoppingCart color='primary' />
          </MIconButton> */}
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}