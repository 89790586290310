import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Grid, Link, Divider, Container, Typography, IconButton, Stack, Box, TextField } from '@material-ui/core';
// routes
import { PATH_PAGE } from '../../routes/paths';
//
import Logo from '../../components/Logo';
import { LoadingButton } from '@material-ui/lab';
import LogoNew from '../../logo/image1.png'
import LogoPartner1 from '../../logo/american express.png'
import LogoPartner2 from '../../logo/solo-pink.png'
import LogoPartner3 from '../../logo/solo-green.png'
import LogoPartner4 from '../../logo/eway.png'
import useAuth from 'src/hooks/useAuth';
// ----------------------------------------------------------------------

const ThumbImgStyle = styled('img')(({ theme }) => ({
  width: 70,
  height: 40,
  objectFit: 'fill',
  opacity:'0.7'
  // margin: theme.spacing(0, 2),
  // borderRadius: theme.shape.borderRadiusSm
}));


const SOCIALS = [
  { name: 'Twitter', icon: 'hugeicons--new-twitter' },
  { name: 'FaceBook', icon: 'bi--facebook' },
  { name: 'Instagram', icon: 'line-md--instagram' },
  { name: 'LinkedIn', icon: 'basil--linkedin-solid' },
];

const itemsImg = [
  {
    imageSrc: LogoPartner1,
    altText: 'Description of image 1',
  },
  {
    imageSrc: LogoPartner2,
    altText: 'Description of image 2',
  },
  {
    imageSrc: LogoPartner3,
    altText: 'Description of image 3',
  },
  {
    imageSrc: LogoPartner4,
    altText: 'Description of image 4',
  },
];


const LINKS = [
  {
    headline: 'Location',
  },
  {
    headline: 'Working Hours',
    children: [
      { name: 'Monday: 9 AM to 9 PM', },
      { name: 'Tuesday: 9 AM to 9 PM',  },
      { name: 'Wednesday: 9 AM to 9 PM ', },
    ]
  },
  {
    headline: 'Working Hours',
    children: [
      { name: 'Thursday: 9AM to 9PM', },
      { name: 'Friday: 9 AM to 9PM', },
      { name: 'Saturday: 9 AM to 9 PM', },
      { name: 'Sunday: 10 AM to 8 PM', },
    ]
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: '#232323'
}));


// ----------------------------------------------------------------------

export default function MainFooter() {
  const currentYear = new Date().getFullYear();
  const {user} = useAuth();
  const navigate = useNavigate();
  return (
    <RootStyle>
      <Divider sx={{borderColor:'#232323'}} />
      <Box maxWidth="lg">
        <Grid
          container
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="center"
          alignItems="center"
          sx={{ pt: 2,pb:3, pl: { xs: 3, sm: 3, md: 6 }, pr: { xs: 3, sm: 3, md: 6 }, backgroundColor: '#111111' }}
        >
          <Grid item xs={12} md={4} container justifyContent={{xs:"center", sm:"center", md: "flex-start"}} alignItems={{xs:"center", sm:"center", md: "flex-start"}} sx={{mb:{xs:4,sm:4,md:0}}}>
            <ScrollLink to="move_top" spy smooth>
            <img src={LogoNew} style={{cursor:'pointer',width:'90px',height:'90px'}} alt='Logo' />
              {/* <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} /> */}
            </ScrollLink>
          </Grid>

          <Grid item xs={12} md={4} container direction="column" justifyContent="center" alignItems="center" sx={{mb:{xs:4,sm:4,md:0}}}>
            <TextField
              fullWidth
              sx={{
                mb: 2,
                '& .MuiInputBase-root': {
                  backgroundColor: '#263036',
                  color: 'common.white',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#ccc', 
                  },
                  '&:hover fieldset': {
                    borderColor: '#ccc', 
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#ccc', 
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#ccc', 
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#ccc',
                },
              }}
              label="Email Address"
            />
            <LoadingButton 
              size="large" 
              color='inherit'
              sx={{ 
                width: 'auto', 
                px: 3, 
                boxShadow: 'none', 
                alignSelf: 'center' 
              }} 
              type="submit" 
              variant="contained"
            >
              Subscribe
            </LoadingButton>
          </Grid>
            
          <Grid item xs={12} md={4} container justifyContent={{xs:"center", sm:"center", md: "flex-end"}} alignItems={{xs:"center", sm:"center", md: "flex-end"}}>
            {SOCIALS.map((social) => (
              <>
                <IconButton 
                  key={social.name} 
                  sx={{
                    boxShadow: 'none', 
                    backgroundColor: '#23252a', 
                    mr: 1, 
                    '&:hover': { 
                      backgroundColor: '#ccc', 
                      '& #social-icon': { 
                        color: '#000'
                      }
                    }
                  }}
                >
                  <Stack 
                    className={social.icon} 
                    sx={{ 
                      color: '#979ba3', 
                      fontSize: '2rem' 
                    }}
                    id="social-icon"
                  />
                </IconButton>
              </>
            ))}
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: '#232323',mt:2,mb:2 }} />
        <Grid
          container
          justifyContent="space-between"
          sx={{ textAlign: { xs: 'left', md: 'left' }, backgroundColor:'#111111',pt: 0, pb: 3, pl: { xs: 3, sm: 6, md: 10 }, }}
          spacing={4}
        >
          {LINKS.map((list) => {
            const { headline, children } = list;
            return (
              <Grid item xs={12} sm={6} md={3} key={headline} >
                <Stack spacing={2}>
                  <Typography component="p" variant="overline" color={'common.white'}>
                    {headline}
                  </Typography>
                  {headline !== 'Location' && children.map((link) => (
                    <Link
                      // to={link.href}
                      key={link.name}
                      color="common.white"
                      variant="body2"
                      underline='none'
                      // component={RouterLink}
                      sx={{ display: 'block','&:hover': {opacity: '0.7'}, }}
                    >
                      {link.name}
                    </Link>
                  ))}
                  {headline === 'Location' && (
                    <>
                    <Link
                      color="common.white"
                      variant="body2"
                      underline='none'
                      sx={{ display: 'block', marginTop: '8px' }}
                    >
                      Get Go Address<br />Line 1, Address Line 2<br />Phone: (123) 456-7890
                    </Link>
                    </>
                  )}
                </Stack>
              </Grid>
            );
          })}
        </Grid>

        <Divider sx={{borderColor:'#232323'}} />
        <Grid
          container
          justifyContent={ {xs: 'center', md: 'space-between'} }
          sx={{
            textAlign: 'center',
            backgroundColor: 'black',
            pl: { xs: 3, sm: 3, md: 6 },
            pr: { xs: 3, sm: 3, md: 6 },
            pb:{xs:3,sm:3,md:0}
          }}
        >
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' }, alignItems: 'center' }}>
            <Typography
              component="p"
              variant="body2"
              sx={{
                mt: 3,
                pb: 2,
                fontSize: 13,
                textAlign: { xs: 'center', md: 'left' },
                color: 'text.secondary',
              }}
            >
              Designed by skyniche Production © {user?.facility_name || 'Get Go'} {currentYear}
            </Typography>
          </Grid>
          {/* <Grid item container xs={12} sm={6} md={4} justifyContent={{ xs: 'center', md: 'flex-end' }} sx={{ mt: 1.7,gap:1,pb:{xs:0,sm:0,md:0.5} }}>
            {itemsImg.map((item, index) => (
              <Grid item key={index}>
                <ThumbImgStyle
                  src={item.imageSrc}
                  alt={item.altText}
                />
              </Grid>
            ))}
          </Grid> */}
        </Grid>

      </Box>
    </RootStyle>
  );
}
