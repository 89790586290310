import { Icon } from '@iconify/react';
import ReactApexChart from 'react-apexcharts';
import trendingUpFill from '@iconify/icons-eva/trending-up-fill';
import trendingDownFill from '@iconify/icons-eva/trending-down-fill';
// material
import { alpha, useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Card, Typography, Stack } from '@material-ui/core';
// utils
import { fNumber, fPercent } from '../../../utils/formatNumber';
import { PeopleAltOutlined, WidgetsOutlined } from '@material-ui/icons';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16)
}));

// ----------------------------------------------------------------------


export default function AppTotalDownloads({products}) {
  const theme = useTheme();


  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3,height:'150px' ,backgroundColor:'rgba(255, 255, 255, 0.4)',border:'1px solid #000',backdropFilter: 'blur(8px)',WebkitBackdropFilter: 'blur(8px)',}}>
      <Box sx={{ flexGrow: 1 ,textAlign:'center'}}>
        <WidgetsOutlined style={{color:'#000'}}/>
        <Typography variant="subtitle2">Products</Typography>

        <Typography color='#000' variant="h3">{products? products + 1 :0}</Typography>
      </Box>
    </Card>
  );
}
